import PropTypes from "prop-types";
import React, { createElement } from "react";

import { Grid, Cell, Button } from "../../components/ui";
import {
  ContentItem,
  ContentObject,
  FormattedContentItem,
} from "../../components/content";

import { useStyletron } from "baseui";
import {
  ParagraphLarge,
  DisplaySmall,
  LabelXSmall,
  HeadingLarge,
  LabelMedium,
} from "baseui/typography";

import MapMarker from "../../assets/images/map-marker.inline.svg";

const Salary = ({ slug }) => {
  const [css, theme] = useStyletron();

  return (
    <article data-csweb={`job-${slug}-salary`}>
      <Grid>
        <Cell span={12}>
          <div
            className={css({
              background: "linear-gradient(90deg, #3C00DC 0%, #274CDF 100%)",
              paddingTop: theme.sizing.scale1000,
              paddingBottom: theme.sizing.scale1000,
              [theme.breakpoints.mediaQueries.large]: {
                marginLeft: "-36px",
                marginRight: "-36px",
                paddingLeft: "36px",
                paddingRight: "36px",
              },
              [theme.breakpoints.mediaQueries.medium]: {
                marginLeft: "-16px",
                marginRight: "-16px",
                paddingLeft: "16px",
                paddingRight: "16px",
              },
            })}
          >
            <Grid flex fill alignItems="center">
              <Cell skip={[0, 0, 1]} span={[4, 6, 8]}>
                <HeadingLarge marginTop="scale0" color="white">
                  Job details
                </HeadingLarge>
                <ParagraphLarge color="white">
                  <FormattedContentItem
                    scope="Jobs"
                    accessor={`${slug}.salaryInfo`}
                  />
                </ParagraphLarge>
                <div
                  className={css({
                    display: "flex",
                    alignItems: "flex-end",
                    [theme.breakpoints.mediaQueries.small]: {
                      alignItems: "center",
                      flexDirection: "column",
                    },
                  })}
                >
                  <DisplaySmall
                    color="white"
                    marginTop="scale600"
                    $style={{ lineHeight: 1 }}
                  >
                    <ContentItem scope="Jobs" accessor={`${slug}.salary`} />
                  </DisplaySmall>
                  <LabelXSmall
                    color="white"
                    marginLeft="scale500"
                    width="12ch"
                    $style={{
                      lineHeight: 1.4,
                      textTransform: "uppercase",
                      paddingBottom: "5px",
                    }}
                    data-csweb="offer-salary-basis"
                  >
                    <ContentItem
                      scope="Jobs"
                      accessor={`${slug}.salaryBasis`}
                    />
                  </LabelXSmall>
                  {createElement(MapMarker, {
                    className: css({
                      height: "28px",
                      width: "18px",
                      marginRight: theme.sizing.scale400,
                      marginBottom: theme.sizing.scale200,
                      marginLeft: theme.sizing.scale1000,
                      [theme.breakpoints.mediaQueries.small]: {
                        marginTop: theme.sizing.scale400,
                        marginRight: theme.sizing.scale0,
                        marginLeft: theme.sizing.scale0,
                      },
                    }),
                  })}
                  <LabelMedium
                    color="white"
                    $style={{
                      fontWeight: 700,
                      marginBottom: theme.sizing.scale0,
                    }}
                  >
                    <ContentItem scope="Jobs" accessor={`${slug}.site`} />
                  </LabelMedium>
                </div>
              </Cell>
              <Cell span={[4, 2, 2]}>
                <Button
                  kind="primary"
                  label="Let's do it!"
                  color="white"
                  $style={{
                    float: "right",
                    fontSize: "22px",
                    fontWeight: 600,
                    paddingTop: theme.sizing.scale600,
                    paddingBottom: theme.sizing.scale600,
                    [theme.breakpoints.mediaQueries.medium]: {
                      float: "none",
                      marginTop: theme.sizing.scale900,
                      marginLeft: "auto",
                      marginRight: "auto",
                    },
                  }}
                  $as="a"
                  target="_blank"
                  href={`mailto:careers@codeshine.com?subject=${ContentObject.Jobs[slug].title}%20Job%20Candidate&body=%2F*%20Fill%20with%20you%20message%20and%20make%20sure%20to%20attach%20your%20resume.%20Thank%20you!%20*%2F`}
                >
                  Apply now
                </Button>
              </Cell>
            </Grid>
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

Salary.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default Salary;
