import PropTypes from "prop-types";
import React from "react";

import { Layout } from "../../components/layout";
import { SEO } from "../../components/meta";
import { ContentObject } from "../../components/content";

import Heading from "./job-heading";
import Content from "./job-content";
import Salary from "./job-salary";
import Contact from "./job-contact";

const Job = ({ location }) => {
  const slug = location.pathname.split(`/`)[2];

  return (
    <>
      <SEO
        title={ContentObject.Jobs[slug].title}
        description={ContentObject.Jobs[slug].description}
      />

      <Layout header={{ position: "absolute" }} footer={{ size: "compact" }}>
        <section data-csweb={`job-${slug}`}>
          <Heading slug={slug} />
          <Content slug={slug} />
          <Salary slug={slug} />
          <Contact />
        </section>
      </Layout>
    </>
  );
};

Job.propTypes = {
  location: PropTypes.object,
};

export default Job;
