import PropTypes from "prop-types";
import React, { createElement } from "react";

import { Grid, Cell, Button } from "../../components/ui";
import { ContentItem, ContentObject } from "../../components/content";

import { useStyletron } from "baseui";
import {
  DisplayMedium,
  LabelMedium,
  LabelXSmall,
  DisplayXSmall,
} from "baseui/typography";

import MapMarker from "../../assets/images/map-marker.inline.svg";

const Heading = ({ slug }) => {
  const [css, theme] = useStyletron();

  return (
    <article
      data-csweb={`job-${slug}-heading`}
      className={css({
        backgroundColor: theme.colors.backgroundSecondary,
        paddingTop: "220px",
        paddingBottom: theme.sizing.scale3200,
        [theme.breakpoints.mediaQueries.medium]: {
          paddingTop: theme.sizing.scale3200,
          paddingBottom: theme.sizing.scale1200,
        },
      })}
    >
      <Grid>
        <Cell span={[4, 8, 10]} skip={[0, 0, 1]}>
          <div
            className={css({
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              [theme.breakpoints.mediaQueries.medium]: {
                flexDirection: "column",
              },
            })}
          >
            <div
              className={css({
                display: "flex",
                flexDirection: "column",
                [theme.breakpoints.mediaQueries.medium]: {
                  alignItems: "center",
                },
              })}
            >
              <DisplayMedium
                marginTop="scale0"
                marginBottom="scale0"
                $style={{
                  fontWeight: "bold",
                  [theme.breakpoints.mediaQueries.large]: {
                    textAlign: "center",
                    ...theme.typography.DisplayMedium,
                    fontWeight: "bold",
                    paddingLeft: theme.sizing.scale500,
                    paddingRight: theme.sizing.scale500,
                  },
                  [theme.breakpoints.mediaQueries.small]: {
                    ...theme.typography.DisplayXSmall,
                    marginBottom: theme.sizing.scale500,
                  },
                }}
              >
                <ContentItem scope="Jobs" accessor={`${slug}.title`} />
              </DisplayMedium>
              <div
                className={css({
                  display: "flex",
                  alignItems: "flex-end",
                  [theme.breakpoints.mediaQueries.small]: {
                    alignItems: "center",
                    flexDirection: "column",
                  },
                })}
              >
                <DisplayXSmall marginTop="scale600" $style={{ lineHeight: 1 }}>
                  <ContentItem scope="Jobs" accessor={`${slug}.salary`} />
                </DisplayXSmall>
                <LabelXSmall
                  marginLeft="scale500"
                  width="12ch"
                  $style={{
                    lineHeight: 1.4,
                    textTransform: "uppercase",
                  }}
                  data-csweb="offer-salary-basis"
                >
                  <ContentItem scope="Jobs" accessor={`${slug}.salaryBasis`} />
                </LabelXSmall>
                {createElement(MapMarker, {
                  className: css({
                    height: "28px",
                    width: "18px",
                    marginRight: theme.sizing.scale400,
                    marginLeft: theme.sizing.scale1000,
                    [theme.breakpoints.mediaQueries.small]: {
                      marginTop: theme.sizing.scale400,
                      marginRight: theme.sizing.scale0,
                      marginLeft: theme.sizing.scale0,
                    },
                  }),
                })}
                <LabelMedium $style={{ fontWeight: 700 }}>
                  <ContentItem scope="Jobs" accessor={`${slug}.site`} />
                </LabelMedium>
              </div>
            </div>
            <Button
              kind="primary"
              label="Let's do it!"
              $style={{
                fontSize: "22px",
                fontWeight: 600,
                paddingTop: theme.sizing.scale600,
                paddingBottom: theme.sizing.scale600,
                [theme.breakpoints.mediaQueries.medium]: {
                  marginTop: theme.sizing.scale900,
                },
              }}
              $as="a"
              target="_blank"
              href={`mailto:careers@codeshine.com?subject=${ContentObject.Jobs[slug].title}%20Job%20Candidate&body=%2F*%20Fill%20with%20you%20message%20and%20make%20sure%20to%20attach%20your%20resume.%20Thank%20you!%20*%2F`}
            >
              Apply now
            </Button>
          </div>
        </Cell>
      </Grid>
    </article>
  );
};

Heading.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default Heading;
