import PropTypes from "prop-types";
import React from "react";

import { Grid, Cell } from "../../components/ui";
import { ContentObject, FormattedContentItem } from "../../components/content";

import { useStyletron } from "baseui";
import { ParagraphLarge, HeadingMedium } from "baseui/typography";

const Content = ({ slug }) => {
  const [css, theme] = useStyletron();

  return (
    <article
      data-csweb={`job-${slug}-content`}
      className={css({
        paddingTop: theme.sizing.scale1600,
        paddingBottom: theme.sizing.scale1000,
        [theme.breakpoints.mediaQueries.medium]: {
          paddingTop: theme.sizing.scale600,
          paddingBottom: theme.sizing.scale600,
        },
      })}
    >
      <Grid>
        <Cell span={[4, 7, 7]} skip={[0, 0, 1]}>
          {ContentObject.Jobs[slug].content.map((item, index) => (
            <div
              className={css({
                paddingBottom: theme.sizing.scale200,
                [theme.breakpoints.mediaQueries.medium]: {
                  paddingBottom: theme.sizing.scale0,
                },
              })}
              key={index}
            >
              {item.headline && (
                <HeadingMedium>
                  <FormattedContentItem
                    scope="Jobs"
                    accessor={`${slug}.content.${index}.headline`}
                  />
                </HeadingMedium>
              )}
              {item.text && (
                <ParagraphLarge>
                  <FormattedContentItem
                    scope="Jobs"
                    accessor={`${slug}.content.${index}.text`}
                  />
                </ParagraphLarge>
              )}
            </div>
          ))}
        </Cell>
      </Grid>
    </article>
  );
};

Content.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default Content;
